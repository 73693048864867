<template>
	<div ref="googleMap"></div>
</template>

<script>
// Importing loader module
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
	// Get props from parent component (U can change needed props)
	props: {
		markers: Array,
		center: Object,
		zoom: Number,
		fitness: Boolean,
		maptype: String,
	},
	data() {
		return {
			// Declarate global variable
			google: null,
			map: null,
			apiKey: "AIzaSyDQNm0sg6OhKh6eZvlGhwdIuUl4EYxoQlk",
			mapConfig: {
				// Set lat and lng from props (Important option)
				center: this.center,
				zoom: this.zoom,
				zoomControl: true,
				scaleControl: true,
				scrollwheel: this.mapscrollwheel,
				streetViewControl: false,
				mapTypeId: this.maptype,
				fullscreenControl: true,
				// Google Map Styles: https://mapstyle.withgoogle.com/
				styles: [
					// {
// 						featureType: "all",
// 						elementType: "labels",
// 						stylers: [
// 							{
// 								visibility: "on",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "all",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								saturation: 36,
// 							},
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 40,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "all",
// 						elementType: "labels.text.stroke",
// 						stylers: [
// 							{
// 								visibility: "on",
// 							},
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 16,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "all",
// 						elementType: "labels.icon",
// 						stylers: [
// 							{
// 								visibility: "off",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "administrative",
// 						elementType: "geometry.fill",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 20,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "administrative",
// 						elementType: "geometry.stroke",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 17,
// 							},
// 							{
// 								weight: 1.2,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "administrative.country",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								color: "#e5c163",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "administrative.locality",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								color: "#c4c4c4",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "administrative.neighborhood",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								color: "#e5c163",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "landscape",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 20,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "poi",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 21,
// 							},
// 							{
// 								visibility: "on",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "poi.business",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								visibility: "on",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.highway",
// 						elementType: "geometry.fill",
// 						stylers: [
// 							{
// 								color: "#e5c163",
// 							},
// 							{
// 								lightness: "0",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.highway",
// 						elementType: "geometry.stroke",
// 						stylers: [
// 							{
// 								visibility: "off",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.highway",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								color: "#ffffff",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.highway",
// 						elementType: "labels.text.stroke",
// 						stylers: [
// 							{
// 								color: "#e5c163",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.arterial",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 18,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.arterial",
// 						elementType: "geometry.fill",
// 						stylers: [
// 							{
// 								color: "#575757",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.arterial",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								color: "#ffffff",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.arterial",
// 						elementType: "labels.text.stroke",
// 						stylers: [
// 							{
// 								color: "#2c2c2c",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.local",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 16,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "road.local",
// 						elementType: "labels.text.fill",
// 						stylers: [
// 							{
// 								color: "#999999",
// 							},
// 						],
// 					},
// 					{
// 						featureType: "transit",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 19,
// 							},
// 						],
// 					},
// 					{
// 						featureType: "water",
// 						elementType: "geometry",
// 						stylers: [
// 							{
// 								color: "#000000",
// 							},
// 							{
// 								lightness: 17,
// 							},
// 						],
// 					},
				],
			},
		};
	},
	// Aysnc is important on API 3.0
	async mounted() {
		// Wait map inited
		const googleMapApi = await GoogleMapsApiLoader({
			apiKey: this.apiKey,
		});
		this.google = googleMapApi;
		this.initializeMap();
	},
	methods: {
		pinSymbol(color, vl) {
			return {
				path: "M 0,0 -1,-2 V -43 H 1 V -2 z M 1,-40 H 50 V -20 H 1 z",
				fillColor: color,
				fillOpacity: 1,
				strokeColor: "#999",
				strokeWeight: 3,
				scale: 1,
				labelOrigin: new vl.google.maps.Point(27, -30),
			};
		},
		// Map init function (initing in async mounted)
		initializeMap() {
			// Get container from ref in template
			const mapContainer = this.$refs.googleMap;
			// Create new map
			this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
			// Hack to pass the event to an internal function
			const vl = this;
			var bounds = new this.google.maps.LatLngBounds();
			// Get and Set markers and tooltip on a map
			let icon = {
				url:'/img/map_pin.png', 
				labelOrigin: new this.google.maps.Point(27,28) 
			}
			this.markers.forEach(function (mker) {
				// create Markers from props from parent component (see below)
				const velp = new vl.google.maps.Marker({
					position: {
						lat: mker.lat,
						lng: mker.lng,
					},
					map: vl.map,
					title: mker.caption,
					icon: icon, //vl.pinSymbol(mker.icon.color, vl),
					label: {
						text: ("000" + mker.id).slice(-3),
						color: "#000",
						fontFamily: "sans-serif",
						fontWeight: "bold",
						fontSize: "12px",
					},
				});
				//extend the bounds to include each marker's position
				bounds.extend(velp.position);
				// add click open marker event
				velp.addListener("click", function () {
					vl.$router
						.push({ name: "Place", params: { id: mker.id } })
						.catch(() => {});
				});
			});
			if (this.fitness && this.markers.length != 0) {
				this.map.fitBounds(bounds);
			}
		},
	},
};
</script>
